import Box from '@mui/material/Box';
import { TextFieldProps } from '@mui/material/TextField';
import { MuiTelInput } from 'mui-tel-input';
import { ForwardedRef, forwardRef } from 'react';

import AppInputLabel from './AppInput/AppInputLabel';
import AppInputErrorMessage from './AppInput/AppInputErrorMessage';

export type AppPhoneInputProps = {
  labelRightComponent?: React.ReactNode;
  label?: string;
  error?: string;
  showOptional?: boolean;
  onChange: (value: string) => void;
  value?: string;
} & Omit<TextFieldProps, 'error' | 'onChange' | 'value'>;

const AppPhoneInput = forwardRef(
  (
    {
      label,
      labelRightComponent,
      error,
      sx = {},
      showOptional,
      disabled,
      onChange,
      value,
      ...props
    }: AppPhoneInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <Box
        sx={{
          width: '100%',
          ...sx,
        }}>
        <AppInputLabel
          label={label}
          labelRightComponent={labelRightComponent}
          showOptional={showOptional}
          disabled={disabled}
        />

        <MuiTelInput
          forceCallingCode
          defaultCountry={'SG'}
          disabled={disabled}
          inputRef={ref}
          hiddenLabel={!label}
          onChange={(value) => onChange(value)}
          value={value}
          sx={{
            width: '100%',
            '& fieldset': {
              borderRadius: '6px',
            },
            '& .MuiInputBase-input': {
              height: '1em',
            },
          }}
          {...props}
          error={!!error}
        />

        <AppInputErrorMessage error={error} />
      </Box>
    );
  },
);

export default AppPhoneInput;
