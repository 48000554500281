import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import AppButton from '../../../components/AppButton';
import AppTextInput from '../../../components/AppTextInput';
import AuthLayout from '../../../hocs/AuthLayout';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { otpVerification } from '../redux/authSlice';
import GoBack from './Components/GoBack';
import mixpanel from 'mixpanel-browser';

interface IOTPVerificationForm {
  otp: string;
}

const validationSchema: yup.ObjectSchema<IOTPVerificationForm> = yup.object({
  otp: yup.string().required('OTP is required'),
});

const OTPVerificationPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoadingOTPVerify, passwordResetEmail } = useAppSelector(
    (state) => state.auth,
  );

  useMemo(() => {
    mixpanel.track('Page View - OTP Verification', {
      page: 'OTP Verification Page',
    });
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IOTPVerificationForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      otp: '',
    },
  });

  const onsubmit = handleSubmit(async (values: IOTPVerificationForm) => {
    const response = await dispatch(
      otpVerification({
        email: passwordResetEmail ?? '',
        otp: values.otp ?? '',
      }),
    );

    if (response.meta.requestStatus === 'fulfilled') {
      navigate('/reset-password');
    }
  });

  return (
    <AuthLayout>
      <Typography variant="h5">OTP Verification</Typography>
      <Typography variant="body2" sx={{ mt: 4 }}>
        We've just sent an email containing a verification code to your
        registered email address
      </Typography>

      <Controller
        name="otp"
        control={control}
        render={({ field, fieldState: { invalid } }) => (
          <AppTextInput
            label="OTP"
            placeholder="enter your otp"
            type="text"
            {...field}
            error={errors.otp?.message}
            touched={invalid}
            data-testid="otp"
            sx={{
              mt: 3,
            }}
          />
        )}
      />
      <AppButton
        variant="contained"
        title="Continue"
        disabled={isSubmitting}
        loading={isLoadingOTPVerify}
        onClick={() => {
          onsubmit();
        }}
        sx={{
          mt: 1,
          mb: 4,
        }}
      />
      <GoBack text={'Go back to sign in'} path={'/login'} />
    </AuthLayout>
  );
};

export default OTPVerificationPage;
