import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { Colors } from '../../../../theme/colors';

const GoBack = ({ text, path }: { text: string; path: string }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        ml: -5,
        mr: -5,

        borderTop: 1,
        borderColor: Colors.borderPrimary,
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          ml: 5,
          mr: 5,
          mb: -3,
          mt: 1,
        }}>
        <IconButton
          sx={{ mt: 1 }}
          onClick={() => {
            navigate(path, { replace: true });
          }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="body2"
          sx={{ mt: 2.4, ml: 0.2, color: Colors.fontColorPrimary }}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default GoBack;
