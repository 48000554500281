import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import * as Yup from 'yup';

interface YupPhoneOptions {
  /**
   * A country or countries to restrict the phone number to be from.
   * If defined and a string value, libphonenumber-js will be told to parse the number
   * using that country's standards.
   * If undefined, the libphonenumber-js default parsing logic will be used.
   * If defined and an array, the default parsing logic will be used and then the
   * validation will fail if the parsed number is not apparently from a requested country.
   *
   * @default undefined
   */
  validCountries?: CountryCode | CountryCode[] | undefined;

  /**
   * Whether to apply "strict" validation rather than gentle "it's possible" validation.
   * Recommended that you leave this as false unless you really need strict validation, as
   * phone number standards might have changed since the last time this library was published.
   * @default false
   */
  strictValidation?: boolean;
}

const PHONE_TEST_NAME = 'phone';

declare module 'yup' {
  export interface StringSchema {
    /**
     * Check the string is a valid phone number
     *
     * @param {YupPhoneOptions|undefined} [options=undefined] The options for the validation
     * @param {String} [errorMessage=DEFAULT_MESSAGE] The error message for if the validation fails.
     */
    phone(
      options?: YupPhoneOptions | undefined,
      errorMessage?: string,
    ): StringSchema;
  }
}

const YupPhone = (yup: typeof Yup) => {
  yup.addMethod(
    yup.string,
    PHONE_TEST_NAME,
    function yupPhone(
      options?: YupPhoneOptions | undefined,
      errorMessage?: string,
    ) {
      const errMsg: string =
        errorMessage ?? '${path} must be a valid phone number.';

      return this.test(PHONE_TEST_NAME, errMsg, (value: unknown): boolean =>
        validatePhone(value, options ?? {}),
      );
    },
  );
};

function validatePhone(value: unknown, options: YupPhoneOptions): boolean {
  if (typeof value !== 'string') {
    return false;
  }

  try {
    const parseCountry =
      options.validCountries && !Array.isArray(options.validCountries)
        ? options.validCountries
        : undefined;
    const phoneNumber = parsePhoneNumberFromString(value, parseCountry);
    if (!phoneNumber?.isPossible()) {
      return false;
    }

    if (options.validCountries) {
      const validCountries = Array.isArray(options.validCountries)
        ? options.validCountries
        : [options.validCountries];

      if (
        !phoneNumber.country ||
        !validCountries.includes(phoneNumber.country)
      ) {
        return false;
      }
    }

    return !options.strictValidation || phoneNumber.isValid();
  } catch {
    return false;
  }
}

export default YupPhone;
