import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import YupPassword from 'yup-password';

import AppButton from '../../../components/AppButton';
import AppPhoneInput from '../../../components/AppPhoneInput';
import AppTextInput from '../../../components/AppTextInput';
import AuthLayout from '../../../hocs/AuthLayout';
import { useAppDispatch } from '../../../hooks';
import YupPhone from '../../../utils/YupPhone';
import { register } from '../redux/authSlice';
import {
  RegisterEmployeeRequestBodyDTO,
  RegisterEmployeeResponseBodyDTO,
} from '../types/auth.types';
import { useEffect } from 'react';

YupPassword(yup);
YupPhone(yup);

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  mobile: yup
    .string()
    .phone(
      { strictValidation: true },
      'Please enter a valid mobile number after the country code',
    )
    .required('Mobile is required'),
  password: yup
    .string()
    .required('Password is required')
    .password()
    .minLowercase(1, 'Password must contain at least 1 lower case letter')
    .minUppercase(1, 'Password must contain at least 1 upper case letter')
    .minNumbers(1, 'Password must contain at least 1 number')
    .minSymbols(1, 'Password must contain at least 1 special character')
    .min(8, 'Password is too short')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), ''], 'Passwords must match')
    .required('Confirm Password is required'),
});

type IRegisterForm = yup.InferType<typeof validationSchema>;

const RegisterPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IRegisterForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      mobile: '',
      password: '',
      confirmPassword: '',
    },
  });

  useEffect(() => {
    setValue('name', searchParams.get('name') ?? '');
    const mobileParam = searchParams.get('mobile');
    // Handle the + in mobile number by replacing encoded space with +
    const decodedMobile = mobileParam ? mobileParam.replace(/^\s+/, '+') : '';
    setValue('mobile', decodedMobile);
  }, [searchParams, setValue]);

  const onSubmit = handleSubmit(async (data: IRegisterForm) => {
    const token = searchParams.get('token');
    if (!token) {
      return navigate({
        pathname: '/invite/invalid',
        search: `?token=${token}`,
      });
    }

    const body: RegisterEmployeeRequestBodyDTO = {
      token,
      mobile: String(data.mobile).replace(/\s/g, ''),
      name: data.name,
      password: data.password,
    };

    const response = await dispatch(register(body));

    if (response.meta.requestStatus === 'fulfilled' && response.payload) {
      const responsePayload =
        response.payload as RegisterEmployeeResponseBodyDTO;

      navigate({
        pathname: '/register/success',
        search: `?resId=${encodeURIComponent(
          responsePayload.resId.toString(),
        )}&resName=${encodeURIComponent(responsePayload.resName)}&token=${token}`,
      });
    } else {
      navigate({
        pathname: '/invite/invalid',
        search: `?token=${token}`,
      });
    }
  });

  return (
    <AuthLayout>
      <Typography variant="h5">Register</Typography>

      <Controller
        name="name"
        control={control}
        render={({ field: { onBlur, value, onChange } }) => (
          <AppTextInput
            label="Name"
            placeholder="E.g: John Doe"
            type="text"
            autoComplete="name"
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            error={errors.name?.message}
            data-testid="name"
            sx={{
              marginTop: 4,
            }}
          />
        )}
      />

      <Controller
        name="mobile"
        control={control}
        render={({ field: { onBlur, value } }) => (
          <AppPhoneInput
            label="Mobile"
            placeholder="E.g: 9999999999"
            autoComplete="tel"
            value={value}
            onBlur={onBlur}
            onChange={(value) =>
              setValue('mobile', value, { shouldValidate: true })
            }
            error={errors.mobile?.message}
            data-testid="mobile"
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        render={({ field: { onBlur, value, onChange } }) => (
          <AppTextInput
            label="Password"
            type="password"
            placeholder="New Password"
            autoComplete="new-password"
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            error={errors.password?.message}
            data-testid="password"
          />
        )}
      />

      <Controller
        name="confirmPassword"
        control={control}
        render={({ field: { onBlur, value, onChange } }) => (
          <AppTextInput
            label="Confirm Password"
            type="password"
            placeholder="Confirm New Password"
            autoComplete="new-password"
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            error={errors.confirmPassword?.message}
            data-testid="confirmPassword"
          />
        )}
      />

      <AppButton
        disabled={isSubmitting}
        variant="contained"
        title="Register"
        onClick={() => {
          onSubmit();
        }}
        sx={{
          mt: 3,
        }}
      />
    </AuthLayout>
  );
};

export default RegisterPage;
